import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Users',
    url: '/users',
    icon: 'cil-user',
  },
  {
    name: 'Collections',
    url: '/collections',
    icon: 'cil-factory',
  },
  {
    name: 'NFT',
    url: '/nft',
    icon: 'cil-factory',
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: 'cil-storage',
  },
  {
    name: 'Banners',
    url: '/banners',
    icon: 'cil-storage',
  },
  {
    name: 'Commissions',
    url: '/commissions',
    icon: 'cil-room',
  },{
    name: 'Auction',
    url: '/auction',
    icon: 'cil-room',
  },
  {
    name: 'Sales',
    url: '/sales',
    icon: 'cil-room',
  },
  {
    name: 'Social links',
    url: '/social-links',
    icon: 'cil-room',
  },
  
  
  // {
  //   name: 'Notifications',
  //   url: '/notification',
  //   icon: 'cil-bell',
  // },
  // {
  //   name: 'Offers',
  //   url: '/offers',
  //   icon: 'cil-cart',
  // },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'cil-settings',
  }
];
