import { Injectable } from '@angular/core';
import {
   HttpInterceptor, HttpHandler, HttpRequest
  } from '@angular/common/http';
import { UserService } from './user.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private user: UserService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.user.getToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if(authToken != '') {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken)
      }); 
      return next.handle(authReq)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event: any) => {
            if (event.type == 0) {
              return;
            }
            if (!event.body.status) {
              localStorage.removeItem("token");
              this.router.navigate(['login'])
            }
          },
          // Operation failed; error is an HttpErrorResponse
          error: (error) => {
          }
        }),
      );
    } else {
      const authReq = req.clone(); 
      return next.handle(authReq)
    }


    // send cloned request with header to the next handler.
    
  }
}