<div class="listcontainer">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Auction List
                </div>
                <div class="card-body">
                    <div class="card-body-filter">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="name" placeholder="Search"
                                        [(ngModel)]="searchInput" (keyup.enter)="searchAction()">
                                </div>
                            </div>
                        </div>
                        <!--/.row-->
                        <div class="card-body-filter-action mb-2">
                            <button type="button" class="btn btn-sm btn-primary" (click)="searchAction()"><i
                                    class="fa fa-search"></i><span class="ml-2">Search</span></button>
                            <button type="button" class="btn btn-sm btn-primary" (click)="resetAction()"><i
                                    class="fa fa-refresh"></i><span class="ml-2">Reset</span></button>
                            <!-- <button type="button" class="btn btn-sm btn-dark" routerLink='/collection/add'><i
                                    class="fa fa-user-plus"></i> Add Collection</button> -->
                        </div>
                    </div>
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="imagetd">Image</th>
                                <th>Collection Name</th>
                                <th>NFT Name</th>
                                <th>Owner</th>
                                <th>Minimum Price</th>
                                <th>Wallet Address</th>
                                <th>
                                    Total Offer
                                </th>
                                <th>Description</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <!-- <th>Royalties</th>-->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let collection of collections">
                                <td class="imagetd">
                                    <img [src]="mediaBase + '/images/collection/logo/' + collection.thumb" />
                                    <!-- <img [src]="mediaBase + '/images/collection/logo/nocollection.jpg'"
                                        *ngIf="!collection.image" /> -->
                                </td>
                                <td class="nametd">
                                    {{collection.collection_id?collection.collection_id.name:''}}
                                </td>
                                <td class="nametd">
                                    {{collection.name}}
                                </td>
                                <td class="nametd">
                                    {{collection.current_owner?collection.current_owner.username:''}}
                                </td>
                                <td class="nametd">
                                    {{collection.price}}   {{collection.fait_type=='dollar'?'USD':'ETH'}}
                                </td>
                                <td class="nametd">
                                    {{collection.current_owner?collection.current_owner.metamask_info.id:''}}
                                </td>
                                <td class="nametd">
                                    {{collection.totalOffer}}
                                </td>
                                <td class="nametd">
                                    {{collection.description}}
                                </td>
                                 <td class="nametd">
                                    {{collection.create_date | date: 'MMMM dd, yyyy'}}
                                </td>
                                <td class="nametd">
                                    {{collection.status}}
                                </td>
                                <!-- <td class="nametd">
                                    {{collection.royalties}}
                                </td> -->
                                <td>
                                    <!-- <button type="button" class="btn btn-sm"
                                        [routerLink]="'/categories/edit/' + category._id"><i
                                            class="fa fa-edit"></i></button>
                                    <button type="button" class="btn btn-sm"
                                        (click)="selectedItem = category; confirmationModal.show()"><i
                                            class="fa fa-trash"></i></button> -->
                                    <button type="button" class="btn btn-sm"
                                        [routerLink]="'/offers/list/'+collection._id"><i class="fa fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="collections.length==0 && loading == false">
                                <td colspan="7">
                                    <!-- <alert type="danger" ng-reflect-type="danger">
                                        <div role="alert" class="alert alert-danger text-center mb-0" ng-reflect-ng-class="">
                                            <strong>Oops!</strong> No collections Found
                                        </div>
                                    </alert> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav>
                        <ul class="pagination">
                            <li class="page-item" *ngIf="page!=1"><a class="page-link" href="javascript:void(0)"
                                    (click)="prevPage()">Prev</a></li>
                            <li class="page-item" *ngIf="hasNextPage"><a class="page-link" href="javascript:void(0)"
                                    (click)="nextPage()">Next</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-loader *ngIf="loading"></app-loader> -->