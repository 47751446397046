export const API = {
    base_url: "https://apis.crypton.media",
    user_login: "/user/login",
    user_list: "/user/adminlist",
    user_create: "/user/createuser",
    user_profile: "/user/profile",
    user_update: "/user/updateuser",
    activity_list: "/item/adminhistory", // activity list api
    item_list: "/item/list", // item list api
    category_list: "/category/fulllist", // category list api
    category_detail: "/category/detail", // category detail list api
    category_create: "/category/add", // category add api
    category_update: "/category/edit", // category edit api
    category_delete: "/category/delete", // category delete api
    offer_list: "/item/offers",
    option_set: "/settings/setoptions",
    option_get: "/settings/getoptions",
    collection_list: "/customcollection/fulllist",
    nft_list: '/item/list',
    collection_view: "/collection/detail",
    collectionUpdate: "/customcollection/update",
    homePageNft: '/item/update',
    banner_list: "/settings/getbanner",
    banner_add: "/settings/banner",
    customCollectionDetail: '/customcollection/detail',
    socialAdd: '/cms/socialadd',
    socialEdit: '/cms/socialedit/',
    sociallist: '/cms/socialadminlist',
    socialDelete: '/cms/socialdelete',
    socialDetails: '/cms/socialdetail',
    cmsList: '/cms/list',
    cmsAdd: '/cms/add',
    cmsEdit: '/cms/edit'
}
